import { Nft } from './types'
import { IPFS_GATEWAY } from '../../index'

const Nfts: Nft[] = [

  {
    name: "#224",
    description: 'Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .',
    images: {
      lg: 'stormy-easter-21-lg.png',
      md: 'stormy-easter-21-md.png',
      sm: 'stormy-easter-21-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmZGqWaovULNEMKxBCGnGjh27JQkAyadS6AW4J4Lzf3XBp/easter-storm.png`,
    },
    identifier: '##224',
    id: 12,
  },
  {
    name: '#90',
    description: "Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price.",
    images: {
      lg: 'hiccup-lg.png',
      md: 'hiccup-md.png',
      sm: 'hiccup-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmQ6EE6gkVzAQUdQLLM7CyrnME6LZHCoy92ZERW8HXmyjw/hiccup.png`,
    },
    identifier: '#90',
    id: 10,
  },
  {
    name: '#666',
    description: 'Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .',
    images: {
      lg: 'sleepy-lg.png',
      md: 'sleepy-md.png',
      sm: 'sleepy-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/sleepy.png`,
      blur: 'sleepy-blur.png',
    },
    identifier: '#666',
    id: 5,
  },
  {
    name: '#932',
    description: 'Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .',
    images: {
      lg: 'sunny-lg.png',
      md: 'sunny-md.png',
      sm: 'sunny-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/sunny.png`,
      blur: 'sunny-blur.png',
    },
    identifier: '#932',
    id: 9,
  },
  {
    name: '#564',
    description: "Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .",
    images: {
      lg: 'churro-lg.png',
      md: 'churro-md.png',
      sm: 'churro-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/churro.png`,
      blur: 'churro-blur.png',
    },
    identifier: '#564',
    id: 8,
  },
  {
    name: '#651',
    description: "Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .",
    images: {
      lg: 'dollop-lg.png',
      md: 'dollop-md.png',
      sm: 'dollop-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/dollop.png`,
      blur: 'dollop-blur.png',
    },
    identifier: '#651',
    id: 6,
  },
  {
    name: '#843',
    description: "Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .",
    images: {
      lg: 'twinkle-lg.png',
      md: 'twinkle-md.png',
      sm: 'twinkle-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmYD9AtzyQPjSa9jfZcZq88gSaRssdhGmKqQifUDjGFfXm/twinkle.png`,
      blur: 'twinkle-blur.png',
    },
    identifier: '#843',
    id: 7,
  },
  {
    name: '#754',
    description: 'Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .',
    images: {
      lg: 'swapsies-lg.png',
      md: 'swapsies-md.png',
      sm: 'swapsies-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/swapsies.png`,
      blur: 'swapsies-blur.png',
    },
    identifier: '#754',
    id: 0,
  },
  {
    name: '#2367',
    description: "Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .",
    images: {
      lg: 'drizzle-lg.png',
      md: 'drizzle-md.png',
      sm: 'drizzle-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/drizzle.png`,
      blur: 'drizzle-blur.png',
    },
    identifier: '#2367',
    id: 1,
  },
  {
    name: '#331',
    description: "Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .",
    images: {
      lg: 'blueberries-lg.png',
      md: 'blueberries-md.png',
      sm: 'blueberries-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/blueberries.png`,
      blur: 'blueberries-blur.png',
    },
    identifier: '#331',
    id: 2,
  },
  {
    name: '#521',
    description: "Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price ..",
    images: {
      lg: 'circular-lg.png',
      md: 'circular-md.png',
      sm: 'circular-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/circular.png`,
      blur: 'circular-blur.png',
    },
    identifier: '#521',
    id: 3,
  },
  {
    name: '#10',
    description: 'Cows aim to create a space/platform for people to come together and share regarding their mental health. Future areas and perks can be unlocked by the community through roadmap activation. This is the time Buy/Share this one time opportunity to get your hands on your unique cyber cow NFT in floor price .',
    images: {
      lg: 'sparkle-lg.png',
      md: 'sparkle-md.png',
      sm: 'sparkle-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmXdHqg3nywpNJWDevJQPtkz93vpfoHcZWQovFz2nmtPf5/sparkle.png`,
      blur: 'sparkle-blur.png',
    },
    identifier: '#10',
    id: 49415690323497367527192589960660493342627518785623941163513426847547260928010,
  },
]

export default Nfts
