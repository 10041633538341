import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'MilkerSwap',
  description:
    'The most popular AMM on BSC by user count! Earn CyberCows through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by MilkerSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://pancakeswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('MilkerSwap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('MilkerSwap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('MilkerSwap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('MilkerSwap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('MilkerSwap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('MilkerSwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('MilkerSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('MilkerSwap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('MilkerSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('MilkerSwap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('MilkerSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('MilkerSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('MilkerSwap')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('MilkerSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('MilkerSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('MilkerSwap')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('MilkerSwap')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('MilkerSwap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('MilkerSwap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('MilkerSwap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('MilkerSwap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('MilkerSwap Info & Analytics')}`,
        description: 'View statistics for Milkerswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('MilkerSwap Info & Analytics')}`,
        description: 'View statistics for Milkerswap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Pools')} | ${t('MilkerSwap Info & Analytics')}`,
        description: 'View statistics for Milkerswap exchanges.',
      }
    default:
      return null
  }
}
